import axios from "axios"

const BASE_URL = `${process.env.REACT_APP_API_SERVER_URL}/v1/edit`

type TUploadedFeature = {
    layer_name: string
    feature: {
        geometry: any,
        properties: any
    }
}
type TProposeFeatureResponseBody = {
    nearestAddressPoint: TUploadedFeature
    changedProperties?: {
        [key: string]: any
    }
    autoPopulatedProperties?: {
        [key: string]: any
    }
    allNearestAddressPoints: TUploadedFeature[]
    propertyToFocus?: string
};

export const proposeFeature = async (accessToken: any, organizationId: any, uploadId: any, geometry: any) => {
    return await axios.post<TProposeFeatureResponseBody>(
        `${BASE_URL}/propose`,
        {
            UploadId: uploadId,
            Geometry: geometry,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

export const saveFeature = async (accessToken: any, organizationId: any, uploadId: any, layerName: any, feature: any) => {
    return await axios.post<any>(
        `${BASE_URL}/save`,
        {
            UploadId: uploadId,
            LayerName: layerName,
            Feature: feature,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

export const updateFeature = async (accessToken: any, organizationId: any, uploadId: any, featureId: any, feature: any) => {
    return await axios.post<any>(
        `${BASE_URL}/update`,
        {
            UploadId: uploadId,
            FeatureId: featureId,
            Feature: feature,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

export const deleteFeature = async (accessToken: any, organizationId: any, uploadId: any, featureId: any) => {
    return await axios.post<any>(
        `${BASE_URL}/delete`,
        {
            UploadId: uploadId,
            FeatureId: featureId,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}
