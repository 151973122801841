import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

type FeatureInfoTabProps = { featureData?: { [key: string]: string; } }

export const FeatureInfoTab: React.FC<FeatureInfoTabProps> = ({ featureData }) => {
    return (
        <TableContainer >
            <Table size={'small'}>
                <TableHead>
                    <TableRow style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {featureData && Object.getOwnPropertyNames(featureData)
                        .filter((featurePropKey) => {
                            // note: this bit of code that excludes the _id may not be needed anymore...
                            // filter out certain properties by name
                            const propsToFilterOut = ['_id'];
                            if (propsToFilterOut.indexOf(featurePropKey) > -1) {
                                return false;
                            }
                            // filter out properties with no value
                            const featurePropValue = featurePropKey in featureData ? featureData[featurePropKey] : null
                            return typeof featurePropValue !== 'undefined'
                        })
                        .map((featurePropKey) => {
                            const featurePropValue = featureData[featurePropKey]
                            return (
                                <TableRow key={featurePropKey}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {featurePropKey}
                                    </TableCell>
                                    <TableCell align="right">{featurePropValue}</TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
