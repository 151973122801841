import { Box, Button, Checkbox, Divider,  List, ListItem,  ListItemText, Popover, Typography } from "@mui/material"
import { TLayer } from "../../../hooks/useOrganization"
import React, { useState } from "react"
import { RGBColor, SketchPicker } from "react-color";


type SelectLayerTabProps =
    {
        layerSelection: TLayerSelection[],
        orgLayers: TLayer[],
        updateSelectedLayer: (layer: TLayerSelection) => void
    }

export class TLayerSelection {
    constructor(layerInfo: TLayer, checked: boolean, rgbColor: RGBColor | null | undefined) {
        this.layerInfo = layerInfo;
        this.checked = checked;
        if (!rgbColor) {
            this.rgbColor = { r: 0, g: 0, b: 255 };
        } else {
            this.rgbColor = rgbColor;
        }
    }

    layerInfo: TLayer;
    checked: boolean;
    rgbColor: RGBColor;

    rgb1(): string {
        return `rgba(${this.rgbColor.r}, ${this.rgbColor.g}, ${this.rgbColor.b}, 1)`
    }

    rgb0(): string {
        return `rgba(${this.rgbColor.r}, ${this.rgbColor.g}, ${this.rgbColor.b}, 0)`
    }
}


export const SelectLayerTab: React.FC<SelectLayerTabProps> = ({ layerSelection, updateSelectedLayer }) => {

    const handleToggle = (layerIndex: number) => {
        layerSelection[layerIndex].checked = !layerSelection[layerIndex].checked
        updateSelectedLayer(layerSelection[layerIndex])
    }

    return (
        <React.Fragment>
            <Typography variant="h6">Select Layer</Typography>
            <List>
                {layerSelection.map((layer, layerIndex) => (
                    <>
                    <ListItem key={layerIndex} onClick={() => handleToggle(layerIndex)}>
                        <Checkbox
                            edge="start"
                            checked={layer.checked}
                            tabIndex={-1}
                            disableRipple
                        />
                        <ListItemText primary={layer.layerInfo.name} />
                        {layer.checked ? <ColorPicker updateSelectedLayer={updateSelectedLayer} layer={layer} /> : ""}
                        
                    </ListItem>
                    <Divider />
                    </>
                ))}

            </List>

        </React.Fragment>
    )
}


type ColorPickerProps =
    {
        layer: TLayerSelection,
        updateSelectedLayer: (layer: TLayerSelection) => void
    }

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState(null); // For Popover positioning
    const [open, setOpen] = useState(false); // For controlling Popover visibility

    // Handle click to open the color picker
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    // Handle closing of the color picker
    const handleClose = () => {
        setOpen(false);
    };

    // Handle color change and close the picker
    const handleChangeComplete = (color: any) => {
        props.layer.rgbColor = color.rgb
        props.updateSelectedLayer(
            props.layer
        )
    };

    // Get RGB values from the color

    return (
        <Box onClick={e => { e.stopPropagation() }}>
            <Button
                variant="contained"
                onClick={handleClick}
                sx={{ backgroundColor: props.layer.rgb1(), padding: '10px 10px', minWidth: '20px' }}
            >
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SketchPicker
                    color={props.layer.rgbColor}
                    onChangeComplete={handleChangeComplete}
                />
            </Popover>
        </Box>
    );
};
