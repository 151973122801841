import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import { Form, FormInput, FormStore, FormLabel } from "@ariakit/react"
import React from "react";

export type TAddFeatureTabFormStoreValues = {
    geometry: any
    properties: { name: string, value: unknown }[]
    changedProperties?: { [key: string]: any }
    propertyToFocus?: string
}
type AddFeatureTabProps = {
    form: FormStore<TAddFeatureTabFormStoreValues>,
    isAddingFeature: boolean,
    isAChangedProperty: (changedProperties: { [key: string]: any; } | undefined, propName: string) => boolean
}
export const AddFeatureTab: React.FC<AddFeatureTabProps> = ({
    form,
    isAddingFeature,
    isAChangedProperty
}) => {
    const values = form.useState("values")
    return (
        <React.Fragment>
            <Form store={form}>
                <TableContainer >
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values.properties?.map((_, i) => {
                                let conditionalStyles = isAChangedProperty(values.changedProperties, values.properties[i].name) ? { backgroundColor: 'rgb(251, 224, 228)', opacity: .8 } : undefined
                                let shouldFocusProperty = values.propertyToFocus === values.properties[i].name;
                                console.log(`shouldFocusProperty: ${shouldFocusProperty}`)
                                return (
                                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...conditionalStyles }}>
                                        <TableCell component="th" scope="row">
                                            <FormLabel
                                                name={form.names.properties[i].value}>{values.properties[i].name}</FormLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <FormInput name={form.names.properties[i].value} autoFocus={shouldFocusProperty} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isAddingFeature &&
                    <Grid container direction={'row'} alignItems={'normal'}>
                        <Grid item xs={8} />
                        <Grid item xs={4}>
                            <Button type="submit"><SaveIcon />Save</Button>
                        </Grid>
                    </Grid>
                }
            </Form >
        </React.Fragment>
    )
}
