import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core'
import { Grid, IconButton } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import { useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const sharedCardStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    borderColor: '#ccc',
    margin: '5px 0',
    minHeight: '50px',
    maxHeight: '50px',
    padding: '0 1rem'
}

const sharedCardContentStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 !important',
}

const clickedLinkStyle = {
    backgroundColor: '#0056b3',
    borderColor: '#003f7f'
}

type TIdentifiable = {
    id: string
    name: string
}
export type TSource = TIdentifiable & {
    rawSource?: any
}

type TDraggableSourceProps = {
    source: TSource,
    isLinkClicked: boolean,
    onLinkClick: any
}

const DraggableSource: React.FC<TDraggableSourceProps> = ({ source, isLinkClicked, onLinkClick }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: source.id,
    })


    const style = transform
        ? {
            cursor: 'pointer',
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        }
        : { cursor: 'pointer', }
    return (

        <Card ref={setNodeRef} style={style} sx={sharedCardStyles}>

            <CardContent sx={{ ...sharedCardContentStyles, width: '100%' }}>
                <Grid container spacing={0} sx={{ width: '100%' }}>
                    <Grid xs={5}  {...listeners} {...attributes} sx={{ paddingTop: '.5rem', textAlign: 'left' }}>{source.name}</Grid >
                    <Grid xs={2} ><IconButton sx={isLinkClicked ? clickedLinkStyle : null} color="primary" component="label"
                        onClick={(event) => onLinkClick(event, source.id)}>
                        <LinkIcon />
                    </IconButton></Grid>
                    <Grid xs={5}  {...listeners} {...attributes} ></Grid >
                </Grid>
            </CardContent>

        </Card>

    )
}

export type TTarget = TIdentifiable & {
    rawTarget?: any
}

export type TCorrelation = {
    rawSource?: any
    sourceId: string
    sourceName: string
    rawTarget?: any
    targetId: string
    targetName: string
}

type TTargetCardProps = {
    key: string
    target: TTarget
    mappings: TCorrelation[]
    onRemoveMapping: (target: TCorrelation) => void
    isLinkClicked: boolean
    onLinkClick: (id: string) => void
}
const TargetCard: React.FC<TTargetCardProps> = ({ target, mappings, onRemoveMapping, isLinkClicked, onLinkClick }) => {
    const { isOver, setNodeRef } = useDroppable({
        id: target.id,
    })
    const style = {
        color: isOver ? 'green' : undefined,
    }
    const mappingIdx = mappings.findIndex((m) => m.targetId === target.id)

    if (mappingIdx === -1) {
        return (
            <Card ref={setNodeRef} style={style} sx={sharedCardStyles}>
                <CardContent sx={{ ...sharedCardContentStyles, width: '100%' }}>
                    <Grid container spacing={0}>
                        <Grid xs={5}></Grid>
                        <Grid xs={2}><IconButton sx={isLinkClicked ? clickedLinkStyle : null} color="primary" component="label" onClick={() => onLinkClick(target.id)}>
                            <LinkIcon />
                        </IconButton></Grid>
                        <Grid xs={5} sx={{ paddingTop: '.5rem', textAlign: 'right' }}>{target.name}</Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    const mapping = mappings[mappingIdx]

    return (
        <Card sx={sharedCardStyles} style={{
            color: 'green',
        }}>


            <CardContent sx={{ ...sharedCardContentStyles, width: '100%' }}>
                <Grid container spacing={0} >
                    <Grid xs={5} sx={{ paddingTop: '.5rem', textAlign: 'left' }}>{mapping.sourceName}</Grid>
                    <Grid xs={2}><IconButton color="error" component="label" onClick={() => onRemoveMapping(mapping)}>
                        <LinkOffIcon />
                    </IconButton></Grid>
                    <Grid xs={5} sx={{ paddingTop: '.5rem', textAlign: 'right' }}>{target.name}</Grid>
                </Grid>
            </CardContent>

        </Card>
    )
}

export type TCorrelatorProps = {
    sources: TSource[]
    mappings: TCorrelation[]
    targets: TTarget[]
    onSaveMapping: (mapping: TCorrelation) => void
    onRemoveMapping: (target: TCorrelation) => void

    navToMapping?: {
        navText: string
        onNavigation: (mapping: TCorrelation) => void
    }
}

/**
 * NOTE: Don't use this, it's basically now just for the correlation
 */
const Correlator: React.FC<TCorrelatorProps> = (props) => {
    const windowDimensions = useWindowDimensions()
    const correlatorContainerRef = useRef<HTMLDivElement>(null);
    const [correlatorContainerY, setCorrelatorContainerY] = useState(0)
    const [maxHeightForOverflow, setMaxHeightForOverflow] = useState(window.innerHeight)

    // eslint-disable
    useEffect(() => {
        const current = correlatorContainerRef?.current;
        if (!current || typeof setCorrelatorContainerY !== 'function') {
            return;
        }
        setCorrelatorContainerY(current.getBoundingClientRect().y)
    }, [correlatorContainerRef, setCorrelatorContainerY])
    useEffect(() => {
        setMaxHeightForOverflow(windowDimensions.height - correlatorContainerY);
    }, [windowDimensions.height, correlatorContainerY])

    function handleDragEnd(event: any) {
        console.log('handleDragEnd', event)
        if (!event.over || !event.active) {
            console.log('drag ended outside of a droppable')
            return
        }

        saveSourceAndTarget(event.active.id, event.over.id)
        const matchingSource = props.sources.find((s) => s.id === event.active.id)
        const matchingTarget = props.targets.find((t) => t.id === event.over.id)
        props.onSaveMapping({
            rawSource: matchingSource?.rawSource,
            sourceId: event.active.id,
            sourceName: matchingSource?.name || 'Unknown',
            rawTarget: matchingTarget?.rawTarget,
            targetId: event.over.id,
            targetName: event.over.name
        })
    }

    function saveSourceAndTarget(sourceId: string, targetId: string) {
        setClickedSourceId("")
        setClickedTargetId("")

        const matchingSource = props.sources.find((s) => s.id === sourceId)
        const matchingTarget = props.targets.find((t) => t.id === targetId)
        props.onSaveMapping({
            rawSource: matchingSource?.rawSource,
            sourceId: sourceId,
            sourceName: matchingSource?.name || 'Unknown',
            rawTarget: matchingTarget?.rawTarget,
            targetId: targetId,
            targetName: matchingTarget?.rawTarget.name
        })
    }

    const [clickedSourceId, setClickedSourceId] = useState("")
    const handleSourceButtonClick = (event: any, id: string) => {
        console.log('sourceButtonClick', id)
        event.stopPropagation()
        if (clickedSourceId === id) {
            // reset it if it is the same one
            setClickedSourceId("")
        } else {
            setClickedSourceId(id)
        }


        if (clickedTargetId) {
            saveSourceAndTarget(clickedSourceId, clickedTargetId)
        }

    }

    const [clickedTargetId, setClickedTargetId] = useState("")
    const handleTargetButtonClick = (id: string) => {
        console.log('targetButtonClick', id)

        if (clickedTargetId === id) {
            // reset it if it is the same one
            setClickedTargetId("")
        } else {
            setClickedTargetId(id)
        }

        // if both are selected correlate them
        if (clickedSourceId) {
            saveSourceAndTarget(clickedSourceId, id)
        }

    }

    return (
        <>
            <DndContext
                onDragEnd={handleDragEnd}>
                <Grid container direction="row" sx={{ textAlign: 'center' }}
                    ref={correlatorContainerRef}>
                    {/* note: this margin needs to match the Rules in Models.tsx */}
                    <Grid item xs spacing={2}><h3 style={{ marginTop: '.5rem', marginBottom: '.1rem' }}>My Data</h3></Grid>
                    <Grid item xs spacing={2}><h3 style={{ marginTop: '.5rem', marginBottom: '.1rem' }}>Data Model</h3></Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs spacing={2}><hr /></Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs spacing={2} sx={{ maxHeight: `${maxHeightForOverflow}px`, overflowY: 'auto', overflowX: 'hidden' }}>
                        {props.sources.map((source) => (
                            <DraggableSource key={source.id} source={source}
                                isLinkClicked={clickedSourceId === source.id}
                                onLinkClick={handleSourceButtonClick} />
                        ))}
                    </Grid>

                    <Grid item xs spacing={2} sx={{ maxHeight: `${maxHeightForOverflow}px`, overflow: 'auto' }}>
                        {props.targets.map((target) => (
                            <TargetCard key={target.id} target={target} mappings={props.mappings} onRemoveMapping={props.onRemoveMapping}
                                isLinkClicked={clickedTargetId === target.id}
                                onLinkClick={handleTargetButtonClick} />
                        ))}
                    </Grid>
                </Grid>
            </DndContext>
        </>
    )
}
export default Correlator
