import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Tooltip } from '@mui/material';



// Define the type for your tab
export type TabItem = {
    id: number;
    tooltip?: string;
    label?: string;
    icon: any;
    content: React.ReactNode;
};

// Define the props for your SidePanelTabs component
type SidePanelTabsProps = {
    tabs: TabItem[];
    activeTab: number,
    setActiveTab: (value: number) => void
};

const SidePanelTabs: React.FC<SidePanelTabsProps> = ({ tabs, activeTab, setActiveTab }) => {

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <Grid container >
            <Grid item >
                <Tabs sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                }}
                    orientation="vertical"
                    value={activeTab}
                    onChange={handleTabChange}
                >
                    {tabs.map((tab, index) => (
                        <Tooltip key={tab.id} title={tab.tooltip} disableInteractive arrow>
                            <Tab key={tab.id} icon={tab.icon} label={tab.label} style={{ fontSize: '10px', minWidth: '30px' }} />
                        </Tooltip>
                    ))}
                </Tabs>
            </Grid>
            <Grid item marginLeft={'10px'}>
                {tabs.map((tab, index) => (
                    <div key={tab.id} hidden={index !== activeTab}>
                        {tab.content}
                    </div>
                ))}
            </Grid>
        </Grid>
    );
};

export default SidePanelTabs
