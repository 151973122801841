import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import { Form, FormInput, FormLabel, useFormStore } from "@ariakit/react"
import { useEffect, useState, type FC } from "react";

export type TUpdateFeatureTabFormStoreValues = {
    geometry?: any
    properties: { name: string, value: unknown }[]
}
type UpdateFeatureTabProps = {
    feature: any
    updateFeature: (feature: any) => Promise<void>
}
export const UpdateFeatureTab: FC<UpdateFeatureTabProps> = ({ feature, updateFeature }) => {
    const [saving, setIsSaving] = useState(false)
    const form = useFormStore<TUpdateFeatureTabFormStoreValues>({ defaultValues: { geometry: null, properties: [] } })
    form.useSubmit(async (state: any) => {
        setIsSaving(true)
        const feature = {
            type: "Feature",
            geometry: state.values.geometry,
            properties: state.values.properties.reduce((a: any, v: any) => ({ ...a, [v.name]: v.value }), {})
        }
        await updateFeature(feature);
        setIsSaving(false)
    });
    useEffect(() => {
        const properties = feature?.properties && Object.entries(feature.properties).map(([key, value], i) => ({ name: key, value: value }))
        form.setValues({
            geometry: feature?.geometry ?? null,
            properties: properties ?? [],
        })
    }, [feature?.geometry, feature?.properties, form])
    const values = form.useState("values")
    return (
        <>
            <Form store={form} >
                <TableContainer >
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values.properties.map((_, i) => {
                                return (
                                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <FormLabel
                                                name={form.names.properties[i].value}>{values.properties[i].name}</FormLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <FormInput name={form.names.properties[i].value} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container direction={'row'} alignItems={'normal'}>
                    <Grid item xs={8} />
                    <Grid item xs={4}>
                        <Button type="submit" disabled={saving}><SaveIcon />Save</Button>
                    </Grid>
                </Grid>
            </Form >
        </>
    )
}
