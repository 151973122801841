import ReactMap, { MapRef, ViewState } from 'react-map-gl'
import Shell from '../layout/Shell'
import {
    Alert,
    Card,
    Drawer,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Snackbar,
    useTheme,
} from '@mui/material'
import mapboxgl from 'mapbox-gl'
import { useParams } from 'react-router-dom'
import { useAppContext } from '../App'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { getOrganizations, TOrganizationResult } from '../hooks/useOrganization'
import { useQuery } from 'react-query'
import { DrawControl } from '../components/map/DrawControl'
import { deleteFeature, proposeFeature, saveFeature, updateFeature } from '../api/editController'
import useQueryParams from "../hooks/useQueryParams";
import { useFormStore } from '@ariakit/react'
import { ArrowBackIos, ArrowForwardIos, Info } from "@mui/icons-material";
import LayersIcon from '@mui/icons-material/Layers';
import { getFeatureById } from '../api/featureController'
import bbox from '@turf/bbox'
import { getTemplates } from '../api/modelsController'
import SidePanelTabs, { TabItem } from '../components/map/SidePanelTabs'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { SearchTab } from '../components/map/tabs/SearchFeatureTab'
import { SelectLayerTab, TLayerSelection } from '../components/map/tabs/SelectLayerTab'
import { AddFeatureTab, TAddFeatureTabFormStoreValues } from '../components/map/tabs/AddFeatureTab'
import { FeatureInfoTab } from '../components/map/tabs/FeatureInfoTab'
import { AxiosError } from 'axios'
import React from 'react'
import { TSourceFeatureData, findAndReplaceFieldAutoPopulateTemplateString, findAndReplaceLayerTemplateString } from '../components/map/templateString'
import { UpdateFeatureTab } from '../components/map/tabs/UpdateFeatureTab'
import { ConfirmDialog, TConfirmDialogProps } from '../components/ConfirmDialog'
import MDEditor from '@uiw/react-md-editor'
import { Layers } from '../components/map/Layers'

export type TClickedFeature = {
    layerName: string
    type: string
    geomtery?: any
    properties?: { [key: string]: string }
}

const SELECT_TAB_ID = 0;
const SEARCH_TAB_ID = 1;
const INFO_TAB_ID = 2;
const ADD_TAB_ID = 3;
const UPDATE_TAB_ID = 4;
const DELETE_TAB_ID = 5;

const possibleMapStyles = [
    {
        displayName: "Light",
        mapStyle: "mapbox://styles/mapbox/light-v11"
    },
    {
        displayName: "Dark",
        mapStyle: "mapbox://styles/mapbox/dark-v11"
    },
    {
        displayName: "Satellite",
        mapStyle: "mapbox://styles/mapbox/satellite-streets-v12"
    },
    {
        displayName: "Outdoors",
        mapStyle: "mapbox://styles/mapbox/outdoors-v12"
    },
    {
        displayName: "Standard",
        mapStyle: "mapbox://styles/mapbox/standard"
    },
    {
        displayName: "Navigation",
        mapStyle: "mapbox://styles/mapbox/navigation-day-v1"
    },
]

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const MapPage: React.FC = () => {
    const theme = useTheme();
    const mapRef = useRef<MapRef>(null)
    const drawerRef = useRef(null)
    const { fileUploadId } = useParams<{ fileUploadId: string }>()
    const qp = useQueryParams();
    const currentOrganizationId = qp.get("organizationId")
    const layerName = qp.get("layerName")
    const featureId = qp.get("featureId")
    const { accessToken } = useAppContext()
    const [mapStyle, setMapStyle] = useState<string>("mapbox://styles/mapbox/light-v11")
    const [firstSymbolLayerId, setFirstSymbolLayerId] = useState<string | undefined>()
    const [clickedFeatureLayer, setClickedFeatureLayer] = useState<string>()
    const [layerSelection, setLayerSelection] = useState<TLayerSelection[]>([])
    const [clickedFeature, setClickedFeature] = useState<TClickedFeature | undefined>()
    const [featureData, setFeatureData] = useState<TClickedFeature['properties'] | undefined>()
    const [showFeatureDataDrawer, setShowFeatureDataDrawer] = useState<boolean>(true)
    const [loaded, setLoaded] = useState(false)
    const [isAddingFeature, setAddingFeature] = useState(false)
    const [hasProposedFeature, setHasProposedFeature] = useState(false)
    const [updatingFeature, setUpdatingFeature] = useState<TClickedFeature | undefined>();
    const [viewState, setViewState] = useState<ViewState>({
        // The Woodlands in Gladstone, MO
        latitude: 39.205867,
        longitude: -94.544264,
        zoom: 3.5,
        bearing: 0,
        pitch: 0,
        padding: { bottom: 0, top: 0, right: 0, left: 0 }
    })
    const [viewStateSet, setViewStateSet] = useState(false)
    const [featureIdsToSelected, setFeatureIdsToSelected] = useState<string[]>([])
    const [activeSidePanelTab, setActiveSidePanelTab] = useState(0)
    const [featureSetOnIntialLoad, setFeatureSetOnIntialLoad] = useState(false) 

    const { data: organizationResult } = useQuery<TOrganizationResult | Error>('get-organizations', () =>
        getOrganizations(accessToken!)
    )

    // gets the data models so we can map the systemLayerNames to the source layer names
    const [sourceDataAPLayerName, setSourceDataAPLayerName] = useState('');
    const [sourceDataRCLLayerName, setSourceDataRCLLayerName] = useState('');
    const [systemNameDictionary, setSystemNameDictionary] = useState<any>({});

    const { data: getTemplatesRespBody } = useQuery('get-templates', () => {
        if (!accessToken) {
            return
        }
        return getTemplates(accessToken)
    });

    // snackbar stuff
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const resetSnackbar = () => {
        setOpenSnackbar(false);
        setTimeout(() => {
            setSnackbarAlertSeverity('info')
            setSnackbarAlertContent('')
        }, 0)
    }
    const openAddAPErrorSnackbar = () => {
        setSnackbarAlertSeverity('error')
        setSnackbarAlertContent('Unable to add address point. Make sure your data is correlated.')
        setOpenSnackbar(true)
    }
    const openSuccessSnackbar = (msg: string) => {
        setSnackbarAlertSeverity('success')
        setSnackbarAlertContent(msg)
        setOpenSnackbar(true)
    }
    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        resetSnackbar()
    };

    type TAlertSeverity = 'success' | 'info' | 'warning' | 'error';
    const [snackbarAlertSeverity, setSnackbarAlertSeverity] = useState<TAlertSeverity>('info');
    const [snackbarAlertContent, setSnackbarAlertContent] = useState<ReactNode>('');

    // this is the contextual organization the user belongs to or undefined
    let templateStringMap = new Map<string, string>();
    const _organization = organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs
        ? organizationResult.organizations.find((org) => org.id === currentOrganizationId) : undefined
    const fuModelLayers = _organization?.fuModels?.find((fm) => fm.source === fileUploadId)?.fuModelLayers ?? [];
    for (const fuModelLayer of fuModelLayers) {
        const { source, templateString } = fuModelLayer;
        // set template string map values
        if (typeof source === 'string' && typeof templateString === 'string') {
            templateStringMap.set(source, templateString);
        }
    }

    // map view state effect
    useEffect(() => {
        if (typeof accessToken === 'undefined') {
            return
        }

        if (!organizationResult) {
            return
        }

        if (organizationResult instanceof Error || !organizationResult.belongs) {
            return
        }

        const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

        async function fetchFeatureById(accessToken: string, currentOrganizationId: string, fileUploadId: string, featureId: string) {
            let response = await getFeatureById(accessToken, currentOrganizationId!, fileUploadId, featureId);
            let bboxResult = bbox(response.data?.feature?.geometry)
            const bounds = new mapboxgl.LngLatBounds(
                new mapboxgl.LngLat(bboxResult[0], bboxResult[1]),
                new mapboxgl.LngLat(bboxResult[2], bboxResult[3]),
            )

            setClickedFeature(response.data?.feature)
            setClickedFeatureLayer(response.data?.layerName)
            setShowFeatureDataDrawer(true)
            setFeatureIdsToSelected([featureId])
            try {
                mapRef?.current?.fitBounds(bounds, { maxZoom: 10, duration: 2000 })
            } catch (e) {
                console.log(e)
            }
        }

        if (!currentOrganization) {
            return
        }
        if (loaded && !viewStateSet) {
            setViewStateSet(true)
            const fuId = currentOrganization.fileUploads.find((f: any) => f.uploadId === fileUploadId)?.uploadId

            const orgLayers = currentOrganization.fileUploads.find((f: any) => f.uploadId === fuId)?.layers ?? []

            setLayerSelection(orgLayers.map(ol => {
                return new TLayerSelection(
                    ol,
                    systemNameDictionary[ol.name] === "AddressPoint", //harcode for addresspoints to be selected
                    systemNameDictionary[ol.name] === "AddressPoint" ? { r: 250, g: 0, b: 0 } : { r: 0, g: 0, b: 255 }
                )
            }))
            if (featureId && mapRef.current) {
                fetchFeatureById(accessToken, currentOrganizationId!, fuId!, featureId)
            } else {
                const layerWithMostFeatures = orgLayers.reduce((prev: any, curr: any) => prev?.expectedFeatureCount > curr?.expectedFeatureCount ? prev : curr)
                if (layerWithMostFeatures?.extent?.xMin && layerWithMostFeatures?.extent?.yMin && layerWithMostFeatures?.extent?.xMax && layerWithMostFeatures?.extent?.yMax && mapRef.current) {
                    const bounds = new mapboxgl.LngLatBounds(
                        new mapboxgl.LngLat(layerWithMostFeatures?.extent?.xMin, layerWithMostFeatures?.extent?.yMin),
                        new mapboxgl.LngLat(layerWithMostFeatures?.extent?.xMax, layerWithMostFeatures?.extent?.yMax),
                    )
                    try {
                        mapRef.current.fitBounds(bounds, { padding: 128, duration: 2000 })
                    } catch (e) {
                        console.log(e)
                    }
                }
                setViewState({ ...viewState, ...currentOrganization.initialViewport })
            }
        }
    }, [accessToken, organizationResult, currentOrganizationId, loaded, viewStateSet, viewState, fileUploadId, featureId, systemNameDictionary])

    // layer selection effect
    useEffect(() => {
        
            if (typeof accessToken === 'undefined' || typeof organizationResult === 'undefined') {
                return
            }

            if (!organizationResult) {
                return
            }

            if (organizationResult instanceof Error || !organizationResult.belongs) {
                return
            }

            const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

            if (!currentOrganization) {
                return
            }
            if (!featureSetOnIntialLoad) {
                const fuId = currentOrganization.fileUploads.find(f => f.uploadId === fileUploadId)?.uploadId
                if (!fuId) {
                    setFeatureSetOnIntialLoad(true)
                    return
                }
                if (layerName && layerSelection.length > 0) {
                    setLayerSelection(layerSelection.map(ls => {
                        return layerName === ls.layerInfo.name ? new TLayerSelection(ls.layerInfo, true, ls.rgbColor) : ls
                    }))
                    setFeatureSetOnIntialLoad(true)
                }
                
            }
    }, [accessToken, organizationResult, currentOrganizationId, fileUploadId, layerName, layerSelection, featureSetOnIntialLoad])

    // click a feature effect
    useEffect(() => {
        console.log('clickedFeature useEffect')
        console.log(clickedFeature)

        if (!clickedFeature?.properties) {
            setFeatureData(undefined)

        } else {
            setFeatureData(clickedFeature.properties)
        }
    }, [clickedFeature])

    // takes care of setting the source AP and RCL layer names for usage on the map
    // it does this by correlating the fuModelLayers with the templates and then using
    // the systemLayerName to set the source layer name
    useEffect(() => {
        let newSystemNameDictionary: any = {};
        const allTemplates = getTemplatesRespBody?.data?.templates ?? [];
        const _organization = organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs
            ? organizationResult.organizations.find((org) => org.id === currentOrganizationId) : undefined
        const fuModelLayers = _organization?.fuModels?.find((fm) => fm.source === fileUploadId)?.fuModelLayers ?? [];

        if (!_organization || !fileUploadId || !fuModelLayers || !allTemplates || !Array.isArray(fuModelLayers) || !Array.isArray(allTemplates)) {
            return
        }
        try {
            const currentOrgAndUploadModel = _organization.fuModels?.find((fm) => fm.source === fileUploadId)
            for (const fuModelLayer of fuModelLayers) {
                const { source, destination } = fuModelLayer;
                const matchingTemplate = allTemplates.find((t) => t.objectId === currentOrgAndUploadModel?.destination);
                const matchingLayer = matchingTemplate?.layers?.find((l: any) => l?.name === destination);
                if (matchingLayer) {
                    const systemLayerName = matchingLayer?.systemLayerName;
                    if (typeof source === 'string' && typeof systemLayerName === 'string') {
                        if (systemLayerName === 'AddressPoint') {
                            setSourceDataAPLayerName(source);
                        } else if (systemLayerName === 'RoadCenterline') {
                            setSourceDataRCLLayerName(source);
                        }
                    }
                    if (source && destination) {
                        newSystemNameDictionary[source] = systemLayerName
                    }
                }
            }
        } catch (e) {
            console.warn('Error setting source layer names:', e)
        }
        setSystemNameDictionary(newSystemNameDictionary)
    }, [organizationResult, currentOrganizationId, fileUploadId, getTemplatesRespBody])

    // debug only useEffect
    useEffect(() => {
        console.log(`[DEBUG] sourceDataAPLayerName: ${sourceDataAPLayerName}, sourceDataRCLLayerName: ${sourceDataRCLLayerName}`)
    }, [sourceDataAPLayerName, sourceDataRCLLayerName])

    const drawControlRef = useRef<MapboxDraw>();

    const startAddPoint = useCallback(() => {
        if (isAddingFeature && hasProposedFeature) {
            console.warn('Cannot add a new feature while a feature is already being added')
            return;
        }

        drawControlRef.current?.deleteAll()
        drawControlRef.current?.changeMode('draw_point')
        setAddingFeature(true)
    }, [isAddingFeature, hasProposedFeature]);

    const updateTabOnUpdateFeature = useCallback(async (feature: any) => {
        await updateFeature(accessToken, currentOrganizationId, fileUploadId, feature.properties._id, feature);
        console.log('UPDATE DEBUG', feature)
        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        (mapRef.current?.getSource(`vector-tiles-source-${sourceId}`) as any).reload();
        setUpdatingFeature(undefined)
        setFeatureData(feature.properties)
        setActiveSidePanelTab(INFO_TAB_ID)
    }, [accessToken, currentOrganizationId, fileUploadId, sourceDataAPLayerName])

    // TODO: possibly move form stuff to AddFeatureTab file
    const form = useFormStore<TAddFeatureTabFormStoreValues>({ defaultValues: { geometry: null, properties: [] } })

    form.useSubmit(async (state: any) => {
        drawControlRef.current?.deleteAll()
        const feature = {
            type: "Feature",
            geometry: state.values.geometry,
            properties: state.values.properties.reduce((a: any, v: any) => ({ ...a, [v.name]: v.value }), {})
        }
        setAddingFeature(false)
        setHasProposedFeature(false)
        setActiveSidePanelTab(SELECT_TAB_ID)

        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        await saveFeature(accessToken, currentOrganizationId, fileUploadId, sourceId, feature);
        (mapRef.current?.getSource(`vector-tiles-source-${sourceId}`) as any).reload();
    });

    // TODO: Is this needed?
    // const cancelAddPoint = useCallback(() => {
    //     form.setValues({ geometry: null, properties: [] })
    //     drawControlRef.current?.deleteAll()
    //     drawControlRef.current?.changeMode('simple_select')
    //     setAddingFeature(false)
    //     setHasProposedFeature(false)
    // }, [form])

    const onUpdate = useCallback(async (e: any) => {
        for (const f of e.features) {
            const proposedGeometry = f.geometry
            try {
                const { data: proposalRespBody } = await proposeFeature(accessToken, currentOrganizationId, fileUploadId, proposedGeometry)
                console.log(`[DEBUG] Proposal Response:\n${JSON.stringify(proposalRespBody, null, 2)}`)
                const changedProperties = proposalRespBody?.changedProperties
                const autoPopulatedProperties = proposalRespBody?.autoPopulatedProperties
                const nearestAPProps = proposalRespBody?.nearestAddressPoint?.feature?.properties;
                const propertyToFocus = proposalRespBody?.propertyToFocus;

                const proposedProperties = nearestAPProps && Object.entries(nearestAPProps).map(([key, value], i) => {
                    return { name: key, value: value }
                })

                if (autoPopulatedProperties) {
                    Object.keys(autoPopulatedProperties).forEach((autopp: any) => {
                        const pp = proposedProperties.find((pp: any) => pp.name === autopp)
                        pp.value = findAndReplaceFieldAutoPopulateTemplateString(pp.value, proposedProperties)
                    });
                }

                form.setValues({
                    geometry: f.geometry,
                    properties: proposedProperties,
                    changedProperties,
                    propertyToFocus
                })
                setHasProposedFeature(true)
                setActiveSidePanelTab(ADD_TAB_ID)
            } catch (e) {
                if (e instanceof AxiosError) {
                    console.warn('AxiosError proposing feature:', e.response?.data)
                    if (e.response?.status === 404) {
                        console.warn('Feature not found in database')
                        openAddAPErrorSnackbar()
                    }
                }
                console.warn('Error proposing feature:', e)
            }
        }
    }, [accessToken, currentOrganizationId, fileUploadId, form]);


    const [deleteConfirmState, setDeleteConfirmState] = useState<Omit<TConfirmDialogProps, 'onClose' | 'onConfirm'>>({
        isOpen: false,
        title: 'Delete Feature',
        content: 'Are you sure you want to delete this feature?',
        cancelBtnDisabled: false,
        cancelBtnContent: 'Cancel',
        confirmBtnDisabled: false,
        confirmBtnContent: 'Delete',
    })


    const onDelete = useCallback((e: any) => {
    }, []);


    if (!organizationResult) {
        return <div>Loading...</div>
    }
    if (organizationResult instanceof Error || !organizationResult.belongs) {
        return <div>Loading...</div>
    }

    const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

    if (!currentOrganization) {
        // show an error page
        return <div>Loading...</div>
    }

    const orgLayers = currentOrganization.fileUploads.find((f) => f.uploadId === fileUploadId)?.layers ?? []

    const collectionName = (orgAuth0Name: string, fileUploadId: string) => `org${orgAuth0Name}Gdb_${fileUploadId}`

    const layerUrl = (collectionName: string, layerName: string) => `https://mongeo.azurewebsites.net/tiles/${collectionName}/layer/${layerName}/{z}/{x}/{y}`

    const layerUrlFromName = (layerName: string) => layerUrl(collectionName(currentOrganization.auth0Name, fileUploadId), layerName)


    const onClick = (e: any) => {
        console.log('onClick')
        console.log(e)
        console.log(e.features)
        setShowFeatureDataDrawer(true)
        setFeatureIdsToSelected([])

        if (activeSidePanelTab !== 3) {
            setActiveSidePanelTab(INFO_TAB_ID)
        }

        setClickedFeature(e.features[0])
        console.log(`clickedFeature: ${JSON.stringify(e.features[0])}`)
        setClickedFeatureLayer(e.features[0].sourceLayer)
    }

    const updateSelectedLayer = (updatedLayer: TLayerSelection) => {

        //remove the previous version of the layer
        const modifiedIndex = layerSelection.findIndex(layer => layer.layerInfo.name === updatedLayer.layerInfo.name)
        layerSelection[modifiedIndex] = updatedLayer
        //add the new version of the layer
        setLayerSelection(layerSelection.map(ls => {
            return ls.layerInfo.name === updatedLayer.layerInfo.name ? updatedLayer : ls
        }))

        console.log('Should this next line run?')
        setClickedFeature(undefined) // this triggers the clearing of the feature data in a useEffect
        setClickedFeatureLayer(undefined)
    }

    const isAChangedProperty = (changedProperties: {
        [key: string]: any;
    } | undefined, propName: string) => {
        if (!changedProperties) {
            return false
        }
        return typeof changedProperties[propName] !== 'undefined'
    }

    const _onCloseDeleteConfirmDialog = () => {
        setDeleteConfirmState({
            ...deleteConfirmState,
            content: 'Are you sure you want to delete this feature?',
            isOpen: false,
            cancelBtnDisabled: false,
            confirmBtnDisabled: false,
        })
    };
    const _onConfirmDeleteConfirmDialog = async () => {
        const featureId = clickedFeature?.properties?._id
        if (!featureId) {
            throw new Error('Unable to delete feature without id!')
        }

        // disable the buttons
        setDeleteConfirmState({
            ...deleteConfirmState,
            cancelBtnDisabled: true,
            confirmBtnDisabled: true,
        })

        // hit server to delete feature
        await deleteFeature(accessToken, currentOrganizationId, fileUploadId, featureId);

        // reset some state
        _onCloseDeleteConfirmDialog();
        setClickedFeature(undefined)
        setClickedFeatureLayer(undefined)
        // show success snackbar
        openSuccessSnackbar('Feature deleted successfully')


        // reload the map
        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        (mapRef.current?.getSource(`vector-tiles-source-${sourceId}`) as any).reload();

    };
    const deleteOnClick = async () => {
        const featureData: TSourceFeatureData = {
            layer: clickedFeatureLayer!,
            properties: clickedFeature?.properties!
        }
        const featureMarkdown = findAndReplaceLayerTemplateString(templateStringMap, featureData)
        setDeleteConfirmState({
            ...deleteConfirmState,
            isOpen: true,
            ...(featureMarkdown !== '' ? {
                content: <>
                    <p>Are you sure you want to delete this feature?</p>
                    <hr />
                    <MDEditor.Markdown source={featureMarkdown} style={{ whiteSpace: 'pre-wrap' }} />
                </>
            } : {})
        })
    }

    const updateOnClick = async () => {
        setUpdatingFeature(clickedFeature)
    }

    // TODO: cleanup clicked feature types
    const handleFeatureClickedFromSearchTab = (feature: any) => {

        setLayerSelection(layerSelection.map(ls => {
            return feature.layerName === ls.layerInfo.name ? new TLayerSelection(ls.layerInfo, true, ls.rgbColor) : ls
        }))
        console.log(`feature: ${JSON.stringify(feature)}`)
        setFeatureIdsToSelected([feature.id])
        setClickedFeature(feature.feature)
        setClickedFeatureLayer(feature.layerName)
        let bboxResult = bbox(feature.feature.geometry)
        const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(bboxResult[0], bboxResult[1]),
            new mapboxgl.LngLat(bboxResult[2], bboxResult[3]),
        )
        try {
            mapRef?.current?.fitBounds(bounds, { maxZoom: 15, duration: 2000 })
        } catch (e) {
            console.log(e)
        }
    }

    const addressPointLayerIsCorrelated = sourceDataAPLayerName !== '';

    const clickedFeatureHasId = typeof clickedFeature?.properties?._id === 'string';

    const tabs: TabItem[] = [
        {
            id: SELECT_TAB_ID,
            tooltip: 'Select Layer',
            icon: <LayersIcon />,
            content: <SelectLayerTab layerSelection={layerSelection} orgLayers={orgLayers} updateSelectedLayer={updateSelectedLayer} />
        },
        {
            id: SEARCH_TAB_ID,
            tooltip: 'Search',
            icon: <SearchIcon />,
            content: <SearchTab accessToken={accessToken!} organizationId={currentOrganizationId!} fileUploadId={fileUploadId} templateStringsMap={templateStringMap} handleFeatureClickedFromSearchTab={handleFeatureClickedFromSearchTab} />
        },
        {
            id: INFO_TAB_ID,
            tooltip: 'Feature Info',
            icon: <Info />,
            content: <FeatureInfoTab featureData={featureData} />
        },
        {
            id: ADD_TAB_ID,
            tooltip: 'Add Feature',
            icon: <IconButton disabled={!addressPointLayerIsCorrelated}><ControlPointIcon /></IconButton>,
            content: hasProposedFeature ? <AddFeatureTab form={form} isAddingFeature={isAddingFeature} isAChangedProperty={isAChangedProperty} /> : undefined
        },
        {
            id: UPDATE_TAB_ID,
            tooltip: 'Update Feature',
            icon: <IconButton disabled={!clickedFeatureHasId}><EditIcon /></IconButton>,
            content: <UpdateFeatureTab feature={updatingFeature} updateFeature={updateTabOnUpdateFeature} />
        },
        {
            id: DELETE_TAB_ID,
            tooltip: 'Delete Feature',
            icon: <IconButton disabled={!clickedFeatureHasId}><DeleteIcon /></IconButton>,
            content: undefined
        }

    ];

    return (
        <Shell>
            <Drawer variant="permanent"
                anchor="left"
                open={showFeatureDataDrawer}
                onClose={() => setShowFeatureDataDrawer(false)}
                ref={drawerRef}

                PaperProps={{
                    style: {
                        border: showFeatureDataDrawer ? '' : 'none',
                        background: showFeatureDataDrawer ? '' : 'transparent',
                        padding: '10px',
                        height: 'calc(-64px + 100vh)',
                        position: 'absolute',
                        top: '64px'
                    }
                }}
            >
                <Grid container direction={'row'} alignItems={'normal'}>
                    <Grid item sx={{ display: showFeatureDataDrawer ? '' : 'none' }}>
                        <SidePanelTabs tabs={tabs} activeTab={activeSidePanelTab} setActiveTab={(tabId) => {
                            console.log(`setActiveTab: ${tabId}`)
                            if (tabId === ADD_TAB_ID) {
                                if (!addressPointLayerIsCorrelated) {
                                    return;
                                }
                                startAddPoint()
                                return;
                            }
                            if (tabId === UPDATE_TAB_ID) {
                                if (!clickedFeatureHasId) {
                                    return;
                                }
                                updateOnClick()
                                setActiveSidePanelTab(tabId);
                                return;
                            }
                            if (tabId === DELETE_TAB_ID) {
                                if (!clickedFeatureHasId) {
                                    return;
                                }
                                deleteOnClick()
                                return;
                            }

                            setActiveSidePanelTab(tabId)
                        }} />
                    </Grid>
                    <Grid item sx={{
                        marginLeft: '5px'
                    }}>
                        <IconButton
                            sx={{
                                borderRadius: '20px',
                                background: theme.palette.primary.main,
                                color: theme.palette.secondary.contrastText,
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                            }}
                            onClick={() => setShowFeatureDataDrawer(!showFeatureDataDrawer)}
                        >
                            {showFeatureDataDrawer ? <ArrowBackIos /> : <ArrowForwardIos />}
                        </IconButton>
                    </Grid>
                </Grid>
            </Drawer>

            <ReactMap
                ref={mapRef}
                style={{ width: '100vw', height: 'calc(100vh - 64px)' }}
                mapStyle={mapStyle}
                onMove={e => setViewState(e.viewState)}
                onLoad={(mbe) => {
                    setLoaded(true)

                    const _map = mbe.target;
                    const _layers = _map.getStyle().layers;
                    for (const l of _layers) {
                        if (l.type === 'symbol') {
                            setFirstSymbolLayerId(l.id)
                            break
                        }
                    }
                }}
                {...viewState}
            >

                <Card sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <Select label="Map Styl" value={mapStyle} onChange={(e) => setMapStyle(e.target.value)}>
                        {possibleMapStyles.map((style) => (
                            <MenuItem value={style.mapStyle}>{style.displayName}</MenuItem>
                        ))}
                    </Select>
                </Card>
                <Layers
                    layers={orgLayers}
                    selectedLayers={layerSelection}
                    layerUrlFromName={layerUrlFromName}
                    onClick={onClick}
                    templateStringMap={templateStringMap}
                    featureIdsToSelected={featureIdsToSelected}
                    systemNameDictionary={systemNameDictionary}
                    firstSymbolLayerId={firstSymbolLayerId}
                />
                <DrawControl
                    ref={drawControlRef}
                    displayControlsDefault={false}
                    controls={{}}
                    onCreate={onUpdate}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                />
            </ReactMap>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleCloseSnackbar}>
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarAlertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarAlertContent}
                </Alert>
            </Snackbar>

            {/* BEGIN - DELETE FEATURE STUFF */}
            <ConfirmDialog {...deleteConfirmState} onClose={_onCloseDeleteConfirmDialog} onConfirm={_onConfirmDeleteConfirmDialog} />
            {/* END - DELETE FEATURE STUFF */}
        </Shell >
    )
}

export default MapPage;

