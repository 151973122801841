import { Fragment } from "react/jsx-runtime";
import { TLayer } from "../../hooks/useOrganization";
import { Layer, Source } from "react-map-gl";
import { HoverState } from "./HoverState";
import { ClickState } from "./ClickState";
import { TLayerSelection } from "./tabs/SelectLayerTab";

export const Layers = ({
    layers,
    selectedLayers,
    layerUrlFromName,
    onClick,
    templateStringMap,
    featureIdsToSelected,
    systemNameDictionary,
    firstSymbolLayerId
}: {
    layers: TLayer[],
    selectedLayers: TLayerSelection[],
    layerUrlFromName: (layerName: string) => string,
    onClick: (e: any) => void,
    templateStringMap: Map<string, string>,
    featureIdsToSelected: string[],
    systemNameDictionary: any,
    firstSymbolLayerId?: string
}) => {

    const getLayerColor = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        return info?.rgb1()
    }

    const getLayerHeatMapEnd = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        return info?.rgb1()
    }

    const getLayerHeatMapStart = (layerName: string) => {
        const info = selectedLayers.find(sl => layerName === sl.layerInfo.name)
        return info?.rgb0()
    }

    const mapLayers = (
        layers: TLayer[],
    ): any[] => {
        return layers.filter(layer => layer.type.includes('Polygon') || layer.type.includes('Line') || layer.type.includes('Point')).map((layer) => {
            if (layer.type.includes('Polygon')) {
                return {
                    name: layer.name,
                    minzoom: 4,
                    maxzoom: 20,
                    layers: [
                        {
                            type: 'line',
                            minzoom: 0,
                            maxzoom: 23,
                            paint: {
                                'line-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', 'grey'],
                                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                            },
                            paintSelected: {
                                'line-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                            },
                        },
                        {
                            type: 'fill',
                            minzoom: 0,
                            maxzoom: 23,
                            paint: {
                                'fill-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', 'grey'],
                                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], .3, .2],
                            },
                            paintSelected: {
                                'fill-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], .3, .2],
                            },
                        },
                    ],
                }
            }
            if (layer.type.includes('Line')) {
                return {
                    name: layer.name,
                    minzoom: 8,
                    maxzoom: 20,
                    layers: [
                        {
                            type: 'heatmap',
                            minzoom: 0,
                            maxzoom: 17,
                            paint: {
                                'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, 'rgba(128, 128, 128, 0)', 1, 'rgba(128, 128, 128, 1)'],
                                'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 16, .5, 17, 0],
                            },
                            paintSelected: {
                                'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, getLayerHeatMapStart(layer.name), 1, getLayerHeatMapEnd(layer.name)],
                                'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 16, .5, 17, 0],
                            },
                        },
                        {
                            type: 'line',
                            minzoom: 16,
                            maxzoom: 23,
                            paint: {
                                'line-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', 'gray'],
                                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                            },
                            paintSelected: {
                                'line-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                            },
                        },
                    ],
                }
            }
            return {
                name: layer.name,
                minzoom: 8,
                maxzoom: 20,
                layers: [
                    {
                        type: 'heatmap',
                        minzoom: 0,
                        maxzoom: 17,
                        paint: {
                            'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, 'rgba(128, 128, 128, 0)', 1, 'rgba(128, 128, 128, 1)'],
                            'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 16, .5, 17, 0],
                        },
                        paintSelected: {
                            'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 16, .5, 17, 0],
                            'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, getLayerHeatMapStart(layer.name), 1, getLayerHeatMapEnd(layer.name)],
                        },
                    },
                    {
                        type: 'circle',
                        minzoom: 16,
                        maxzoom: 23,
                        paint: {
                            'circle-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', 'gray'],
                            'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                        },
                        paintSelected: {
                            'circle-color': ['case', ['boolean', ['feature-state', 'click'], false], 'cyan', getLayerColor(layer.name)],
                            'circle-radius': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 4],
                        },
                    },
                ],
            }
        })
    }

    //only render the selected layers
    const renderLayers = mapLayers(layers.filter(layer => selectedLayers.some(sl => sl.layerInfo.name === layer.name && sl.checked)))
    return (
        <>
            {renderLayers
                .filter((l) => !selectedLayers.some(sl => sl.layerInfo.name === l.name))
                .map((l) => {
                    const sourceId = `vector-tiles-source-${l.name}`
                    const layerId = `features-layer-${l.name}`
                    return (
                        <Fragment key={l.name}>
                            <Source id={sourceId} minzoom={l.minzoom} maxzoom={l.maxzoom} type="vector" tiles={[layerUrlFromName(l.name)]} promoteId="_id">
                                {l.layers.map((layer: any, index: any) => {
                                    return (
                                        <Layer id={`${layerId}-${index}`} beforeId={firstSymbolLayerId} minzoom={layer.minzoom} maxzoom={layer.maxzoom} source-layer={l.name} type={layer.type} paint={layer.paint} />
                                    )
                                })}
                            </Source>
                        </Fragment>
                    )
                })}
            {renderLayers
                .filter((l) => selectedLayers.some(sl => sl.layerInfo.name === l.name))
                .map((l) => {
                    const sourceId = `vector-tiles-source-${l.name}`
                    const layerId = `features-layer-${l.name}`
                    return (
                        <Fragment key={l.name}>
                            <Source id={sourceId} type="vector" tiles={[layerUrlFromName(l.name)]} promoteId="_id">
                                {l.layers.map((layer: any, index: any) => {
                                    return (
                                        <Layer id={`${layerId}-${index}`} beforeId={firstSymbolLayerId} minzoom={layer.minzoom} maxzoom={layer.maxzoom} source-layer={l.name} type={layer.type} paint={layer.paintSelected} />
                                    )
                                })}
                            </Source>
                            <HoverState source={sourceId} sourceLayer={l.name} layerId={`${layerId}-1`} templateStrings={templateStringMap} />
                            <ClickState source={sourceId} sourceLayer={l.name} layerId={`${layerId}-1`} onClick={onClick} selectedIds={featureIdsToSelected} />
                        </Fragment>
                    )
                })}
        </>
    )
}
