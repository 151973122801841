import { useEffect, useRef } from 'react'
import { useMap } from 'react-map-gl'

export const ClickState = ({
    source,
    sourceLayer,
    layerId,
    selectedIds,
    onClick,
}: {
    source: string,
    sourceLayer: string,
    layerId: string,
    selectedIds: string[],
    onClick?: (e: any) => void,
}) => {
    const { current: map } = useMap()
    // there could be multiple featureIds highlighted or 'clicked
    const { current } = useRef<{ featureIds: string[] | number[] }>({ featureIds: [] })
    useEffect(() => {
        if (current && map) {
            const mouseClick = (e: any) => {
                onClick && onClick(e)
                if (e.features && e.features?.length > 0) {
                    // remove all the currently clicked
                    if (current.featureIds?.length > 0) {
                        for (let featureId of current.featureIds) {
                            map?.removeFeatureState({ source, sourceLayer, id: featureId }, 'click')
                        }
                    }
                    // this occurs on mouse click. Only one is selected
                    if (current) {
                        current.featureIds = [e.features[0].id]
                        map?.setFeatureState({ source, sourceLayer, id: current.featureIds[0] }, { click: true })
                    }
                }
            }

            // you can pass in whatever featureIds you want in order to select them
            if (selectedIds.length > 0) {
                for (let featureId of current.featureIds) {
                    map?.removeFeatureState({ source, sourceLayer, id: featureId }, 'click')
                }
                for (let featureId of selectedIds) {
                    map?.setFeatureState({ source, sourceLayer, id: featureId }, { click: true })
                }
                current.featureIds = selectedIds
            }
            map.on('click', layerId, mouseClick)


            return () => {
                map.off('click', layerId, mouseClick)
            }
        }
    }, [current, layerId, map, onClick, source, sourceLayer, selectedIds])
    return <></>
}
