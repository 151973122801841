import axios from "axios";
import qs from "qs";

/**
 *     public class GetFeatureAttributesModel
    {
        public bool Success { get; set; }
        public string Type { get; set; }
        public IEnumerable<string> Attributes { get; set; }
    }
 */

export type TGetFeaturePropertiesByLayerNameModel = {
    success: boolean;
    layerName: string;
    properties: string[];
};

export const getFeaturePropertiesByLayerName = async (
    accessToken: string,
    layerName: string,
    organizationId: string,
    fileUploadId: string,
) => {
    return axios.get<TGetFeaturePropertiesByLayerNameModel>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/layername/properties/${layerName}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId,
            },
        },
    );
};

// public class GetFeaturesFromFileByLayerNameModel {
//     public bool Success { get; set; }

//     public IEnumerable<Feature> Features {get; set;}
// }

// public  class Feature {

//     public string LayerName { get; set;}

//     public object Properties { get; set; }

// }


export type TGetFeaturesFromFile = {
    features: TFeature[]
}

export type TFeature = {
    layerName: string
    featureId: string;
    properties: TProperty[]
}

export type TProperty = {
    key: string
    value: string
}


export const getFeatureByLayerName = async (
    accessToken: string,
    layerName: string,
    organizationId: string,
    fileUploadId: string,
    page: number,
    pageSize: number,
    fields?: string[],
    searchTerm?: string
) => {

    let params: Record<string, any> = {
        organizationId: organizationId,
        fileUploadId: fileUploadId,
        page: page,
        pageSize: pageSize,
    }

    if (searchTerm && searchTerm !== '') {
        params.searchTerm = searchTerm
    }

    if (fields && fields.length > 0) {
        params.fields = fields
    }

    return axios.get<TGetFeaturesFromFile>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/layername/${layerName}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat', encode: false })
            }
        },
    )
};

//TODO CHRIS TYPE THIS RESPONSE
export const getFeatureById = async (
    accessToken: string,
    organizationId: string,
    fileUploadId: string,
    featureId: string,
) => {

    return axios.get<any>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/${featureId}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId,
            },
        },
    )
};

export type TGetFeaturesLayerNameWithFeatures = {
    id: string,
    // todo type this
    featureList: any[]
}
export const getFeatureBySearchTerm = async (
    accessToken: string,
    organizationId: string,
    fileUploadId: string,
    searchTermstring: string
) => {

    return axios.get<TGetFeaturesLayerNameWithFeatures[]>(
        `${process.env.REACT_APP_API_SERVER_URL}/v1/features/search-term/${searchTermstring}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId,
                fileUploadId,
            },
        },
    )
};

