import { useEffect, useRef, useState } from 'react'
import { Popup, PopupProps, useMap } from 'react-map-gl'
import { TSourceFeatureData, findAndReplaceLayerTemplateString } from './templateString'
import MDEditor from '@uiw/react-md-editor'

export const HoverState = ({ source, sourceLayer, layerId, templateStrings }: { source: string, sourceLayer: string, layerId: string, templateStrings: Map<string, string> }) => {
    const { current: map } = useMap()
    const { current } = useRef<{ featureId: string | number | undefined }>({ featureId: undefined })
    const [popupProps, setPopupProps] = useState<PopupProps | undefined>();

    useEffect(() => {
        if (current && map) {
            // TODO: Add a proper type to this event
            const mouseEnter = (e: any) => {
                if (e.features && e.features?.length > 0) {
                    if (current) {
                        const sourceFeatureData: TSourceFeatureData = {
                            layer: sourceLayer,
                            properties: e.features[0].properties
                        }
                        const popupMarkdown = findAndReplaceLayerTemplateString(templateStrings, sourceFeatureData)
                        if (popupMarkdown === '') {
                            // TODO: Should this render something?
                            return
                        }

                        setPopupProps({
                            longitude: e.lngLat.lng,
                            latitude: e.lngLat.lat,
                            children: <MDEditor.Markdown source={popupMarkdown} style={{ whiteSpace: 'pre-wrap' }} />
                        });
                    }
                }
            }
            // TODO: Add a proper type to this event
            const mouseLeave = (e: any) => {
                if (current) {
                    setPopupProps(undefined)

                }
            }
            map.on('mouseenter', layerId, mouseEnter)
            map.on('mouseleave', layerId, mouseLeave)
            return () => {
                map.off('mouseenter', layerId, mouseEnter)
                map.off('mouseenter', layerId, mouseLeave)
            }
        }
    }, [current, layerId, map, source, sourceLayer, templateStrings])

    if (popupProps) {
        return (
            <Popup {...popupProps}
                onClose={(e) => {
                    console.log(`Popup.onClose(e)\n${JSON.stringify(e, null, 2)}`)
                    setPopupProps(undefined)
                }} />
        )
    }
    return <></>
}
