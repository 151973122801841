import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { TFileUpload } from '../hooks/useOrganization';
import { getFeatureByLayerName, getFeaturePropertiesByLayerName } from '../api/featureController';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useAppContext } from '../App';
import { Link } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map'


const FeatureTable: React.FC<{ currentOrganizationId: string, file: TFileUpload }> = ({ currentOrganizationId, file }) => {
    const pageSize = 50;
    const tableContainerRef = useRef<HTMLDivElement>(null)
    const { accessToken } = useAppContext()
    const [layer, setLayer] = useState(file.layers[0])
    const [searchFields, setSearchFields] = useState<string[]>([])
    const [inputValue, setInputValue] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('')
    const maxResults = layer.expectedFeatureCount
    const fileUploadId = file.uploadId
    let resultsFetched = 0
    var lastScrollLeft = 0;

    const { data: featureKeysResponse, isLoading: arePropertiesLoading } = useQuery(['get-feature-properties-by-layername', layer.name], () => {
        if (!accessToken || !currentOrganizationId) {
            return
        }
        if (maxResults === 0) {
            return
        }
        return getFeaturePropertiesByLayerName(accessToken, layer.name, currentOrganizationId, fileUploadId)
    })

    const { data: featuresResponse, fetchNextPage: getFeatures, isFetching, isLoading, status } = useInfiniteQuery(
        ['get-features', layer.name, searchFields, searchTerm],
        ({ pageParam = 1 }) => {
            var data = getFeatureByLayerName(accessToken!, layer.name, currentOrganizationId, fileUploadId, pageParam, pageSize, searchFields, searchTerm)
            return data
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                resultsFetched = ((allPages.length - 1) * pageSize) + lastPage.data.features.length
                if (resultsFetched < maxResults) {
                    return allPages.length + 1;
                }
                return undefined;
            }
        }
    );

    useEffect(() => {

        var tableRef = tableContainerRef.current
        tableRef?.addEventListener('scroll', scrollListener)

        return () => {
            tableRef?.removeEventListener('scroll', scrollListener)
        }
    })

    function debounce(func: (...args: any[]) => void, wait: number) {
        let timeout: NodeJS.Timeout;
        return function executedFunction(...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    const scrollListener = debounce(() => {
        const container = tableContainerRef.current;
        if (container) {
            const { scrollTop, clientHeight, scrollHeight, scrollLeft } = container;

            if (scrollLeft !== lastScrollLeft) {
                lastScrollLeft = scrollLeft
                return;
            }

            const isScrollAtBottom = (scrollTop + clientHeight >= (scrollHeight * 0.70));

            if (isScrollAtBottom && resultsFetched < maxResults) {
                getFeatures()
            }
        }

    }, 200)

    useEffect(() => {
        if (featureKeysResponse) {
            setSearchFields(featureKeysResponse.data.properties)
        }
    }, [featureKeysResponse]);

    const handleFetching = () => {
        if (isFetching || isLoading) {

            if (status === 'error') {
                //handle errors
            }

            return ((
                <CircularProgress size={24} />

            ))
        }
        return <></>

    }

    const handleLayerChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value as string

        var layer = file.layers.find(layer => layer.name === newValue)
        setSearchTerm('')
        setInputValue('')
        setLayer(layer!)
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event && event.target) {
            setSearchTerm(event.target.value)
        }
    }

    const handleKeyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event && event.target && event.key === 'Enter') {
            setSearchTerm(inputValue)
        }
    }

    const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };


    return (

        <Grid container spacing={2} marginTop={'40px'}>
            <Grid item xs={3} alignContent={'center'} justifyContent={'center'}>
                <Box display="flex"
                    height={'100%'}
                    width={'100%'}>
                    <FormControl sx={{ m: 1, minWidth: 240 }}>
                        <InputLabel id="fuln-input-label">Layer Name</InputLabel>
                        <Select
                            labelId='fuln-input-label'
                            label="Layer Name"
                            value={layer.name} onChange={handleLayerChange}>
                            {file.layers.map((layer, index) => (
                                <MenuItem key={index} value={layer.name}>{layer.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    height={'100%'}
                    width={'100%'}>
                    <TextField
                        variant="outlined"
                        placeholder="search"
                        value={inputValue}
                        fullWidth
                        onBlur={handleOnBlur}
                        onKeyDown={handleKeyboardEvent}
                        onChange={handleChangeEvent}
                    />
                </Box>
            </Grid>


            <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <p> {resultsFetched} out of {maxResults} </p>
            </Grid>

            <Grid item xs={12} fontSize={10}>
                <TableContainer style={{ maxHeight: '70vh' }} ref={tableContainerRef}>
                    {arePropertiesLoading &&
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
                            <CircularProgress size={24} />
                        </div>}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell size='small' align='center' style={{
                                    position: 'sticky',
                                    top: 0,
                                    left: 0,
                                    zIndex: 2,
                                    backgroundColor: 'white'
                                }}>Map</TableCell>
                                {featureKeysResponse?.data.properties.map((item, index) => (
                                    <TableCell style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}
                                        size='small' align='center' key={index}>{item}</TableCell>
                                ))}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {featuresResponse?.pages.map(page => (
                                page.data.features.map((feature, index) => (
                                    <TableRow key={index}>
                                        <TableCell size='small' align='center' style={{
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 1,
                                            backgroundColor: 'white'
                                        }}>
                                            <Link
                                                to={`/map/${file.uploadId}?organizationId=${currentOrganizationId}&layerName=${layer.name}&featureId=${feature.featureId}`}
                                                component={IconButton}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <MapIcon />

                                            </Link>
                                        </TableCell>
                                        {feature.properties.map((property, propIndex) => (
                                            <TableCell size='small' align='center'
                                                key={propIndex}>{property.value}</TableCell>
                                        ))}
                                    </TableRow>

                                ))))}
                            <TableRow>
                                <TableCell size='small' align='center'
                                    style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}>
                                    {handleFetching()}
                                </TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}


export default FeatureTable;
