import axios from 'axios'
import { useEffect, useState } from 'react'

export type TExtent = {
    xMin?: number
    yMin?: number
    xMax?: number
    yMax?: number
}

export type TLayer = {
    name: string
    type: string
    status: string
    createdAt: string
    insertedFeatureCount?: number
    expectedFeatureCount: number
    color?: string
    extent?: TExtent
}

export type TFileUpload = {
    uploadId: string
    friendlyName: string
    fileName: string
    status: string // queued | failed | importing | imported
    createdAt: string
    layers: TLayer[]
    processingAt?: string
    processedAt?: string
    failedAt?: string
}

export type TFileDownload = {
    _id: string
    uploadId: string
    friendlyName: string
    status: string // queued | failed | generating | generated
    createdAt: string
    layers: TLayer[]
    processingAt?: string
    processedAt?: string
    failedAt?: string
    downloadUrl?: string
}

export type TInitialViewport = { latitude: number, longitude: number, zoom: number }

type TFuModelLayer = {
    source?: string // uploaded layer name
    destination: string // nenas layer for example
    templateString?: string
    autoPopulateSources?: TFuPopulatedFieldSouce[]

}

type TFuPopulatedFieldSouce = {
    sourceFieldName: string
    autoPopulateTemplateString: string

}
type TFUModel = {
    source?: string // upload id
    destination: string // nena for example
    fuModelLayers: TFuModelLayer[]
}

export type TOrganization = {
    id: string
    auth0DisplayName: string
    auth0Name: string
    initialViewport: TInitialViewport
    fileUploads: TFileUpload[]
    fileDownloads: TFileDownload[]
    fuModels?: TFUModel[]
}

type TOrganizationsResponse = {
    organizations: TOrganization[]
}

export type TSuccessOrganizationResult = | ({
    belongs: true
    accessToken: string
    organizations: TOrganization[]
})
export type TErrorOrganizationResult = {
    belongs: false
    accessToken: string
    error?: any
}
export type TOrganizationResult = TSuccessOrganizationResult | TErrorOrganizationResult

export const getOrganizations = async (accessToken: string): Promise<TOrganizationResult | Error> => {
    try {
        // const {getAccessTokenSilently} = useAuth0()
        // const accessToken = await getAccessTokenSilently()
        // if (!accessToken) {
        //   throw Error('No access token')
        // }

        const response = await axios.get<TOrganizationsResponse>(
            `${process.env.REACT_APP_API_SERVER_URL}/v1/organizations`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        if (response.data.organizations.length > 0) {
            return { belongs: true, accessToken: accessToken, organizations: response.data.organizations };
        }
        return { belongs: false, accessToken };
    } catch (error) {
        console.error(error)
        return error as Error;
    }
}

export default function useOrganization(accessToken: string) {
    const [organizationInfo, setOrganizationIfno] = useState<TOrganizationResult | Error>()

    useEffect(() => {
        const _getOrganizations = async () => {
            const _organizationInfo = await getOrganizations(accessToken)
            if (!_organizationInfo) {
                return;
            }

            setOrganizationIfno(_organizationInfo)
        }

        if (accessToken) {
            _getOrganizations()
        }
    }, [accessToken])

    return organizationInfo

}
